import { MyApiRequest } from 'framework/api/my-api';
import {
    AuthAccessTokenBody,
    AuthCodeBody,
    AuthOtpBody,
    AuthVerifyReCaptchaBody
} from '../../models/auth';
import { validateResponseUpdate } from 'framework/api/dto/common';

export function getAuth(request: MyApiRequest) {
    async function postAuthOtp(body: AuthOtpBody) {
        const response = await request('auth_get_otp', 'POST', body);

        return JSON.parse(response.body);
    }

    async function postVerifyReCAPTCHA(body: AuthVerifyReCaptchaBody) {
        const response = await request('verify_recaptcha', 'POST', body);

        return validateResponseUpdate(JSON.parse(response.body));
    }

    async function postAuthCode(body: AuthCodeBody) {
        const response = await request('auth_get_code', 'POST', body);

        return JSON.parse(response.body);
    }

    async function postAuthAccessToken(body: AuthAccessTokenBody) {
        const response = await request('auth_get_access_token', 'POST', body);

        return JSON.parse(response.body);
    }

    return {
        postAuthOtp,
        postAuthCode,
        postAuthAccessToken,
        postVerifyReCAPTCHA
    };
}
