import React, { useCallback, useEffect } from 'react';
import { Box, LinearProgress, useMediaQuery } from '@mui/material';
import { useGlobalStyles } from '../../GlobalStyles.style';
import { useStyles } from './invitation.style';
import Logotype from '../../resoruces/svgx/logotype';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RouteN } from 'routing/routes-path';
import useTheme from '@mui/system/useTheme';
import Error404 from 'features/404/error-404';
import { useQueryGetInvitationById } from 'framework/api/hooks/invitation/get-invitation-by-id';
import { TYPE_DATA_INVITATION } from 'framework/api/models/invitation';
import { useSignedImage } from 'framework/utils/hooks/signed-image';
import ProductInvitationHeader from 'features/invitation/components/product-invitation-header';
import KuboInvitationHeader from 'features/invitation/components/kubo-invitation-header';
import KuboInvitationInfo from 'features/invitation/components/kubo-invitation-info/kubo-invitation-info';

type InvitesLayoutProps = {
    showKuboInvitation?: boolean;
};

const routesDownMdWithCompleteScreen = new RegExp(
    `${RouteN.invites.children.smsVerification}|${
        RouteN.invites.children.setAlias
    }|${RouteN.inviteSuccessfully.route.replace('/:id', '')}|${RouteN.inviteRejected.route.replace(
        '/:id',
        ''
    )}`
);

const InvitationLayout = (props: InvitesLayoutProps) => {
    const { showKuboInvitation = true } = props;

    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { data: dataInfo, isLoading } = useQueryGetInvitationById(id || '');
    const { imageSigned, signedImage } = useSignedImage();
    const { classes: styles } = useStyles();
    const { classes: globalStyles } = useGlobalStyles();

    const showKuboInfo = useCallback(() => {
        return !matches || !routesDownMdWithCompleteScreen.test(location.pathname);
    }, [matches, location.pathname]);

    useEffect(() => {
        if (!dataInfo) return;
        const imgSigned =
            dataInfo.typeData === TYPE_DATA_INVITATION.PRODUCT
                ? dataInfo.backgroundImgUrl
                : dataInfo.product.backgroundImgUrl;

        signedImage(imgSigned).catch();
    }, [dataInfo]);

    if (isLoading) {
        return (
            <LinearProgress
                data-testid={'invitation-loading'}
                sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}
            />
        );
    }

    if (!dataInfo) {
        return <Error404 />;
    }

    return (
        <>
            <Box className={styles.overlay} />
            <img className={styles.imgContainer} src={imageSigned} loading='lazy' alt={'bg'} />
            <Box className={styles.container}>
                <Box className={globalStyles.containerInvites}>
                    {showKuboInfo() && (
                        <Box className={styles.containerLogo}>
                            {dataInfo.typeData === TYPE_DATA_INVITATION.PRODUCT ? (
                                <ProductInvitationHeader productType={dataInfo.productType} />
                            ) : dataInfo.typeData === TYPE_DATA_INVITATION.KUBO ? (
                                <KuboInvitationHeader
                                    productType={dataInfo.product.productType}
                                    ownerAlias={dataInfo.ownerInfo.alias}
                                />
                            ) : null}
                            <Box>
                                <Logotype />
                            </Box>
                        </Box>
                    )}
                    <Box className={styles.containerData}>
                        {showKuboInfo() && showKuboInvitation && (
                            <KuboInvitationInfo itemInfo={dataInfo} />
                        )}
                        <Box flex={1} width={'100%'} height={'100%'} alignSelf={'flex-end'}>
                            <Outlet
                                context={{
                                    isProduct: dataInfo.typeData === TYPE_DATA_INVITATION.PRODUCT,
                                    isKubo: dataInfo.typeData === TYPE_DATA_INVITATION.KUBO,
                                    kuboType: 'type' in dataInfo ? dataInfo.type : ''
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InvitationLayout;
