const KEY: string = process.env.REACT_APP_TOKEN_SIGNED_IMAGES || '';
const EXPIRATION: number = 60 * 60 * 24; // 1 day

const bufferToHex = (buffer: ArrayBuffer): string =>
    [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');

export const generateSignedUrl = async (url: URL): Promise<string> => {
    const encoder = new TextEncoder();
    const secretKeyData = encoder.encode(KEY);
    const key = await crypto.subtle.importKey(
        'raw',
        secretKeyData,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    );

    // Attach the expiration value to the `url`
    const expiry = Math.floor(Date.now() / 1000) + EXPIRATION;
    url.searchParams.set('exp', expiry.toString());

    const stringToSign = url.pathname + '?' + url.searchParams.toString();

    // Generate the signature
    const mac = await crypto.subtle.sign('HMAC', key, encoder.encode(stringToSign));
    const sig = bufferToHex(new Uint8Array(mac).buffer);

    // And attach it to the `url`
    url.searchParams.set('sig', sig);

    return url.toString();
};

export const clearCloudfareUrl = (url: string | undefined) => {
    return !!url ? url.replace('?sig=<INSERT_SIGNED_TOKEN>', '') : '';
};
