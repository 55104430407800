import React from 'react';
import { Box, Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';
import CostTypesItem from 'features/components/cost-types/cost-types-item/cost-types-item';
import { useStyles } from 'features/components/cost-types/cost-types-variable-product/cost-types-variable-product.style';
import { MarketingPrice } from 'framework/api/models/product';

type CostTypesVendorAdminProps = {
    marketingPrice: MarketingPrice[];
};

const CostTypesVariableProduct = (props: CostTypesVendorAdminProps) => {
    const { classes: styles } = useStyles();
    const { t } = useI18nService();

    return (
        <Box className={styles.container}>
            <Typography color={'common.whiteC.c100_64'} variant={'body12Medium'}>
                {t('costTypesTitleAdminVendor')}
            </Typography>
            <Box className={styles.containerMinMax}>
                {props.marketingPrice.map((mp, index) => (
                    <CostTypesItem key={index} quantity={mp.priceView} amount={mp.members} />
                ))}
            </Box>
        </Box>
    );
};

export default CostTypesVariableProduct;
