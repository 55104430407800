import React from 'react';
import { Product, PRODUCT_TYPE } from 'framework/api/models/product';
import { Box, Divider, Link, Typography } from '@mui/material';
import SharedAvatar, { TypeAvatarSize } from 'framework/web/shared/shared-avatar/shared-avatar';
import { useStyles } from '../kubo-invitation-info.style';
import CostTypesVariableProduct from 'features/components/cost-types/cost-types-variable-product/cost-types-variable-product';
import CostTypesClosedProduct from 'features/components/cost-types/cost-types-closed-product/cost-types-closed-product';
import { useI18nService } from 'framework/i18n/i18n-service';

type ProductInfoProps = {
    product: Product;
    onClickShowMore: () => void;
};

const ProductInfo = (props: ProductInfoProps) => {
    const { t } = useI18nService();
    const { classes: styles } = useStyles();

    return (
        <>
            <Box className={styles.containerVendorInfo}>
                <SharedAvatar image={props.product.vendor.logo} size={TypeAvatarSize.s40} />
                <Typography color={'common.whiteC.c100'} variant={'body14Medium'}>
                    {props.product.vendor.name}
                </Typography>
            </Box>

            <Box className={styles.containerDescription}>
                <Typography
                    className={styles.title}
                    color={'common.whiteC.c100'}
                    variant={'Heading1'}
                >
                    {props.product.name}
                </Typography>

                <Typography
                    dangerouslySetInnerHTML={{ __html: props.product.description }}
                    className={styles.description}
                    color={'common.whiteC.c100'}
                    variant={'body14Regular'}
                />

                <Link
                    component={'button'}
                    color={'common.whiteC.c100'}
                    style={{ alignSelf: 'flex-end', marginTop: 10 }}
                    variant={'body14Regular'}
                    onClick={props.onClickShowMore}
                >
                    {t('showMore')}
                </Link>
            </Box>

            <Divider className={styles.dividerSlideMembers} />

            {props.product.productType === PRODUCT_TYPE.VARIABLE ? (
                <CostTypesVariableProduct marketingPrice={props.product.marketingPrice} />
            ) : props.product.productType === PRODUCT_TYPE.CLOSED ? (
                <CostTypesClosedProduct
                    marketingPrice={props.product.marketingPrice}
                    title={t('costTypesClosedProductTitle')}
                />
            ) : null}
        </>
    );
};

export default ProductInfo;
