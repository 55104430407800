import { TYPE_DATA_INVITATION } from 'framework/api/models/invitation';

export type Vendor = {
    name: string;
    logo?: string;
    termOfServicesUrl: string;
};

export enum PRODUCT_STATUS {
    AVAILABLE = 1,
    COMING_SOON,
    SOLD_OUT
}

export enum PRODUCT_TYPE {
    CLOSED = 'closed',
    VARIABLE = 'variable'
}

export type MarketingPrice = {
    priceView: string;
    members: number;
};

export type Product = ClosedProduct | VariableProduct;

export type ProductBase = {
    typeData: TYPE_DATA_INVITATION.PRODUCT;
    id: string;
    name: string;
    price: number;
    priceCents: number;
    stock: number;
    totalStock: number;
    category?: string;
    pageUrl: string;
    status: PRODUCT_STATUS;
    isPublished: boolean;
    expirationDate?: Date;
    backgroundVideoUrl: string;
    backgroundImgUrl: string;
    imagesUrL: string[];
    description: string;
    info: string;
    marketingTags: string[];
    vendor: Vendor;
    marketingPrice: MarketingPrice[];
    voucherRedeemUrl?: string;
    voucherRedeemInfo?: string;
};

interface ClosedProduct extends ProductBase {
    productType: PRODUCT_TYPE.CLOSED;
    members: number;
    minMembers: number;
    maxMembers: number;
}

interface VariableProduct extends ProductBase {
    productType: PRODUCT_TYPE.VARIABLE;
    suggestedMinMembers: number;
    suggestedMaxMembers: number;
    minMembers: number;
    maxMembers: number;
}
