import { createTheme } from '@mui/material/styles';
import { theme } from './theme';
import { appTypographys } from './app-typography';
import { appComponents } from './app-components';

const appTheme = createTheme({
    palette: {
        primary: {
            main: theme.palette.primary.main,
            light: theme.palette.primary.light
        },
        secondary: {
            main: theme.palette.secondary.main,
            light: theme.palette.secondary.light
        },
        text: {
            primary: theme.palette.text.primary,
            secondary: theme.palette.text.secondary,
            input: theme.palette.text.input
        },
        background: {
            paper: theme.palette.background.paper,
            default: theme.palette.background.default
        },
        error: {
            main: theme.palette.error.main
        },
        success: {
            main: theme.palette.success.main
        },
        warning: {
            main: theme.palette.warning.main
        },
        info: {
            main: theme.palette.info.main
        },
        common: {
            black: theme.palette.common.black,
            white: theme.palette.common.white,
            transparent: theme.palette.common.transparent,
            red: theme.palette.common.red,
            green: theme.palette.common.green,
            orange: theme.palette.common.orange,
            blue: theme.palette.common.blue,
            light_blue: theme.palette.common.light_blue,
            grey: theme.palette.common.grey,
            overlay: theme.palette.common.overlay,
            whiteC: theme.palette.common.whiteC,
            primary: theme.palette.common.primary,
            secondary: theme.palette.common.secondary,
            gradient: theme.palette.common.gradient,
            alert: theme.palette.common.alert,
            success: theme.palette.common.success,
            warning: theme.palette.common.warning
        },
        divider: theme.palette.divider
    },
    breakpoints: {
        keys: theme.breakpoints.keys,
        values: {
            sm: theme.breakpoints.values.sm,
            md: theme.breakpoints.values.md,
            lg: theme.breakpoints.values.lg
        }
    },
    zIndex: theme.zIndex,
    typography: appTypographys,
    spacing: theme.spacing,
    components: appComponents
});

export default appTheme;
