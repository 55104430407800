import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((_theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerMinMax: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        width: 'fit-content'
    }
}));
