import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerMinMax: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        width: 'fit-content'
    },
    divider: {
        '&.MuiDivider-root': {
            borderColor: theme.palette.common.whiteC.c100_16
        }
    }
}));
