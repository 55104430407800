import { useQuery } from '@tanstack/react-query';
import { useMyApi } from '../../api-provider';
import { getInvitationById } from '../query-keys';
import { useEffect } from 'react';

export function useQueryGetInvitationById(id: string) {
    const api = useMyApi();

    const { isFetching, isError, data, error, refetch, isSuccess } = useQuery({
        queryKey: getInvitationById(id),
        queryFn: () => api.invitations().getInvitationByIdS3(id)
    });

    useEffect(() => {
        if (!isError || !error) return;

        console.error(`useQueryGetInvitationById ${error}`);
    }, [isError]);

    return {
        data,
        isLoading: isFetching,
        isError,
        refetch,
        isSuccess
    };
}
