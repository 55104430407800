import { useState } from 'react';
import { generateSignedUrl } from 'framework/utils/cloudfare-images-sign';

export const useSignedImage = () => {
    const [imageSigned, setImageSigned] = useState('');

    const signedImage = async (img?: string) => {
        if (!img) return;

        try {
            const url = await generateSignedUrl(new URL(img));
            setImageSigned(url);
        } catch {
            console.error('Error to signed URL');
        }
    };

    return {
        signedImage,
        imageSigned
    };
};
