import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((_theme: Theme) => ({
    containerPersons: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            color: 'white'
        }
    }
}));
