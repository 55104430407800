import { Components, Theme } from '@mui/material';
import { theme } from './theme';

export const appComponents: Components<Omit<Theme, 'components'>> = {
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                '&::-webkit-scrollbar, *::-webkit-scrollbar': {
                    width: 18,
                    height: 14,
                    backgroundColor: theme.palette.common.white
                },

                '&::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    backgroundColor: '#150D18',
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box'
                },
                '&::-webkit-scrollbar-thumb:focus, *::-webkit-scrollbar-thumb:focus': {
                    backgroundColor: '#7D7D7D'
                },
                '&::-webkit-scrollbar-thumb:active, *::-webkit-scrollbar-thumb:active': {
                    backgroundColor: '#7D7D7D'
                },
                '&::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#7D7D7D'
                }
            }
        }
    },
    MuiLink: {
        defaultProps: {
            color: theme.palette.text.secondary,
            fontWeight: 600
        },
        styleOverrides: {
            root: {
                cursor: 'pointer'
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 8,
                height: 44,
                padding: '12px 24px 12px 24px'
            },
            containedPrimary: {
                ':disabled': {
                    backgroundColor: theme.palette.common.primary.c100_08,
                    '& .MuiTypography-root': {
                        color: theme.palette.common.whiteC.c100_40
                    }
                },
                '&.MuiLoadingButton-loading': {
                    backgroundColor: theme.palette.primary.main
                },
                '& .MuiCircularProgress-root': {
                    color: 'white'
                }
            },
            containedSecondary: {
                ':disabled': {
                    backgroundColor: theme.palette.common.whiteC.c100_08,
                    '& .MuiTypography-root': {
                        color: theme.palette.common.whiteC.c100_24
                    }
                },
                '&.MuiLoadingButton-loading': {
                    backgroundColor: theme.palette.secondary.main
                },
                '& .MuiCircularProgress-root': {
                    color: 'white'
                }
            }
        }
    },
    MuiFilledInput: {
        styleOverrides: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'inherit',
                    WebkitTextFillColor: 'inherit',
                    caretColor: 'inherit'
                }
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color'
                }
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: 0,
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'inherit',
                    WebkitTextFillColor: 'inherit',
                    caretColor: 'inherit'
                }
            },
            root: {
                height: '44px !important',
                padding: '12px 16px',
                color: theme.palette.common.whiteC.c100,
                backgroundColor: theme.palette.common.whiteC.c100_08,
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.common.whiteC.c100_08}`
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.common.whiteC.c100_08}`
                },
                '&:focus .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.common.whiteC.c100_08}`
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.common.whiteC.c100_08}`
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.error.main}`
                }
            }
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            input: {
                height: '100%',
                alignItems: 'center',
                display: 'flex'
            },
            inputRoot: {
                height: '44px !important',
                backgroundColor: theme.palette.common.whiteC.c100_08,
                padding: '12px 16px',
                alignItems: 'center',
                display: 'flex',
                color: theme.palette.common.whiteC.c100
            },
            popupIndicator: {
                padding: 12,
                color: theme.palette.common.whiteC.c100
            }
        }
    }
};
