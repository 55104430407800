import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    containerInvitation: {
        maxWidth: 383,
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            maxWidth: 1000
        }
    },
    containerLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        marginBottom: 8
    },
    containerCurrentMembers: {
        width: 19,
        height: 18,
        backgroundColor: theme.palette.common.grey.c100_80,
        borderRadius: 16,
        borderColor: theme.palette.common.whiteC.c100,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    textCurrentMembers: {
        textAlign: 'center',
        lineHeight: 15.8
    }
}));
