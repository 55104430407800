export const RouteN = {
    invites: {
        route: '/:id',
        children: {
            smsVerification: 'sms-verification',
            setAlias: 'set-alias'
        }
    },
    inviteRejected: {
        route: '/:id/rejected'
    },
    inviteSuccessfully: {
        route: '/:id/successfully'
    },
    redeem: {
        route: '/:id/canjear'
    },
    error404: {
        route: '/expired'
    }
};
