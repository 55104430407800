import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import appTheme from './theme/app-theme';
import 'framework/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MyNotificationsProvider } from 'framework/notifications/notifications-service';
import { MyI18nProvider } from 'framework/i18n/i18n-service';
import { MyApiProvider } from 'framework/api/api-provider';
import { buildRoutes } from 'routing/framework';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from 'routing/framework/routes';

export const muiCache = createCache({
    key: 'mui',
    prepend: true
});

export const AppDependencies: React.FC<
    React.PropsWithChildren<{
        queryClient: QueryClient;
        testing?: boolean;
    }>
> = ({ children, queryClient, testing = false }) => {
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <MyI18nProvider>
                    <QueryClientProvider client={queryClient}>
                        <MyApiProvider>
                            {children}
                            <ReactQueryDevtools initialIsOpen={false} />
                            <MyNotificationsProvider />
                        </MyApiProvider>
                    </QueryClientProvider>
                </MyI18nProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};

const AppRouter = () => {
    return <RouterProvider router={createBrowserRouter(buildRoutes(routes))} />;
};

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: true,
                refetchOnReconnect: true,
                retry: 0,
                staleTime: 5 * 60 * 1000
            }
        }
    });

    return (
        <AppDependencies queryClient={queryClient}>
            <AppRouter />
        </AppDependencies>
    );
};

export default App;
