export const formatMoneyWithTwoDecimals = (value: number) => {
    const valueFix = value === Infinity ? 0 : value || 0;
    return new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    })
        .format(valueFix)
        .replace(/\xa0/g, '');
};

export const deleteCentsUnnecessary = (value: string | undefined) => {
    if (!value) return '';
    return value.replace(',00', '');
};
