import { useQueryClient } from '@tanstack/react-query';
import { useAuthInvites } from 'framework/providers/auth-invites-provider';

export const useCloseSession = () => {
    const queryClient = useQueryClient();
    const [, setAuth] = useAuthInvites();

    const closeSession = () => {
        setAuth({
            isLoggedIn: false,
            isLoadingData: false,
            phone: '',
            alias: '',
            imageBase64: '',
            userId: ''
        });
        queryClient.removeQueries();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.history.replaceState({ logout: true }, '');
    };

    return {
        closeSession
    };
};
