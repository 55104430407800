import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useStyles } from 'features/components/cost-types/cost-types-division-variable/cost-types-division-variable.style';
import { useI18nService } from 'framework/i18n/i18n-service';

import CostTypesItem from 'features/components/cost-types/cost-types-item/cost-types-item';

type CostTypesDivisionProps = {
    minMembers: number;
    maxMembers: number;
    maxMemberCostView: string;
    minMemberCostView: string;
};

const CostTypesDivisionVariable = (props: CostTypesDivisionProps) => {
    const { classes: styles } = useStyles();
    const { t } = useI18nService();

    return (
        <Box className={styles.container}>
            <Typography color={'common.whiteC.c100_64'} variant={'body12Medium'}>
                {t('minMax')}
            </Typography>
            <Box className={styles.containerMinMax}>
                <CostTypesItem
                    quantity={props.maxMemberCostView}
                    text={t('howMuchWillYouPay')}
                    amount={props.minMembers}
                />
                <Divider
                    flexItem
                    sx={{ borderStyle: 'dashed' }}
                    className={styles.divider}
                    orientation={'vertical'}
                />
                <CostTypesItem
                    quantity={props.minMemberCostView}
                    text={t('youCanGetToPay')}
                    amount={props.maxMembers}
                />
            </Box>
        </Box>
    );
};

export default CostTypesDivisionVariable;
