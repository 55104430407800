import React from 'react';
import { Box, Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';
import { MarketingPrice } from 'framework/api/models/product';
import { useGlobalStyles } from '../../../../GlobalStyles.style';

type CostTypesVendorAdminProps = {
    marketingPrice: MarketingPrice[];
    title: string;
};

const CostTypesClosedProduct = (props: CostTypesVendorAdminProps) => {
    const { classes: globalStyles } = useGlobalStyles();
    const { t } = useI18nService();

    const getFirstMarketingPrice = () => {
        if (!props.marketingPrice.length) return '';
        return props.marketingPrice[0].priceView;
    };

    const getFirstMarketingMembers = () => {
        if (!props.marketingPrice.length) return 0;
        return props.marketingPrice[0].members;
    };

    return (
        <Box>
            <Typography typography={'body12Medium'} color={'common.whiteC.c100_64'}>
                {props.title}
            </Typography>

            <Typography mb={8} style={{ color: 'white' }} typography={'DisplayMini'}>
                {t('planForNPeople', { total: getFirstMarketingMembers() })}
            </Typography>
            <Typography
                className={globalStyles.fillWhiteText}
                color={'common.grey.c100'}
                typography={'DisplaySuperSmall'}
            >
                {t('perPeople', { price: getFirstMarketingPrice() })}
            </Typography>
        </Box>
    );
};

export default CostTypesClosedProduct;
