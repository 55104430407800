import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthInvites } from 'framework/providers/auth-invites-provider';
import { goToError404, goToSms } from 'routing/framework/navigates/invites';
import { ReactNode } from 'react';
import { LinearProgress } from '@mui/material';

export const SingInGuard = (props: { children: ReactNode }) => {
    const [auth] = useAuthInvites();
    let params = useParams();
    const location = useLocation();
    const state = location.state as {
        phone: string;
    };

    if (auth.isLoadingData) {
        return (
            <LinearProgress
                data-testid={'loading-get-me'}
                sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}
            />
        );
    }

    if (!params || !params.id) {
        return <Navigate to={goToError404()} replace />;
    }

    if (!auth.isLoggedIn) {
        if (!!state && !!state.phone) {
            return <Navigate to={goToSms(params.id)} replace />;
        }
        return props.children;
    }

    return props.children;
};

export default SingInGuard;
