import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    containerKuboInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerDescription: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    containerVendorInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        marginBottom: 12
    },
    title: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    },
    description: {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    },
    dividerSlideMembers: {
        marginTop: 20,
        marginBottom: 12,
        '&.MuiDivider-root': {
            borderColor: theme.palette.common.whiteC.c100_16
        }
    },
    dividerMinMax: {
        marginTop: 20,
        '&.MuiDivider-root': {
            borderColor: theme.palette.common.whiteC.c100_16
        }
    },
    showMoreInfoDrawerPaper: {
        background: 'rgba(26, 27, 39, 0.8)',
        maxHeight: '80%'
    }
}));
