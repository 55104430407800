import { RouteN } from 'routing/routes-path';

export const goToError404 = () => {
    return RouteN.error404.route;
};

export const goToInvitesSignIn = (id: string = '') => {
    return RouteN.invites.route.replace(':id', id);
};

export const goToSms = (id: string) => {
    return RouteN.invites.route.replace(':id', id) + '/' + RouteN.invites.children.smsVerification;
};

export const goToSuccessfully = (id: string) => {
    return RouteN.inviteSuccessfully.route.replace(':id', id);
};

export const goToRejected = (id: string) => {
    return RouteN.inviteRejected.route.replace(':id', id);
};
