import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './translation/es.json';
import { defaultI18nProvider } from 'framework/i18n/i18n-service';

i18n.use(initReactI18next)
    .init({
        resources: {
            es: { translation: es }
        },
        fallbackLng: defaultI18nProvider.currentLanguage,
        lng: 'es'
    })
    .catch(i18nError => {
        console.error('Error i18n', i18nError);
    });

export default i18n;
