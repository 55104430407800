import { ReactElement, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutesType } from './routes';
import { LinearProgress } from '@mui/material';

export const buildRoutes = (routes: RoutesType[]): RouteObject[] => {
    return routes.map(r => {
        if (!r.children) {
            return {
                path: r.path,
                index: true,
                id: r.id,
                element: addSuspenseIfNecessary(r.element, r.suspense)
            };
        }
        return {
            path: r.path,
            index: false,
            id: r.id,
            element: addSuspenseIfNecessary(r.element, r.suspense),
            children: buildRoutes(r.children)
        };
    });
};

const addSuspenseIfNecessary = (children: ReactElement, suspenseNecessary: boolean) => {
    if (suspenseNecessary) {
        return (
            <Suspense
                fallback={
                    <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0 }} />
                }
            >
                {children}
            </Suspense>
        );
    }

    return children;
};
