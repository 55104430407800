import React from 'react';

type PersonProps = {};

const Logotype = (props: PersonProps) => {
    return (
        <>
            <svg
                width='88'
                height='88'
                viewBox='0 0 88 88'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M27.2036 57.2506C26.6664 57.2502 26.1391 57.1057 25.6767 56.8323C25.2143 56.5588 24.8337 56.1664 24.5745 55.6958C24.3153 55.2253 24.187 54.6939 24.203 54.1569C24.219 53.6199 24.3787 53.0971 24.6654 52.6428L32.948 39.5528C33.2342 39.1001 33.6363 38.7321 34.1126 38.4871C34.5889 38.242 35.122 38.1288 35.6568 38.1591C36.1915 38.1895 36.7085 38.3622 37.154 38.6596C37.5995 38.9569 37.9574 39.368 38.1905 39.8502L44.5274 52.9378C44.7492 53.3957 44.851 53.9024 44.8234 54.4105C44.7957 54.9186 44.6394 55.4113 44.3692 55.8424C44.0989 56.2735 43.7236 56.6289 43.2783 56.8752C42.8331 57.1215 42.3326 57.2507 41.8238 57.2506H27.2036Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M21.1538 14.3994C21.154 13.7221 21.4232 13.0726 21.9023 12.5937C22.3813 12.1148 23.0309 11.8458 23.7083 11.8458H40.7208C41.3504 11.8399 41.9612 12.0599 42.4425 12.4659C43.7462 13.5879 43.9686 15.2697 43.1432 16.5701L24.5443 45.6354C23.5534 47.1835 21.1538 46.482 21.1538 44.6437V14.3994Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M52.5884 57.2708C52.0186 57.2686 51.4609 57.1072 50.978 56.8048C50.4951 56.5025 50.1063 56.0712 49.8555 55.5597L38.8204 32.8712C38.5943 32.4096 38.4896 31.8981 38.5163 31.3848C38.5429 30.8715 38.7001 30.3735 38.9728 29.9379L49.3405 13.279C49.6121 12.8393 49.992 12.4767 50.4438 12.2258C50.8956 11.9748 51.4042 11.844 51.921 11.8457H63.5199C64.3257 11.8466 65.0983 12.1671 65.668 12.7369C66.2378 13.3066 66.5583 14.0792 66.5592 14.885V54.2316C66.5583 55.0374 66.2378 55.8099 65.668 56.3797C65.0983 56.9495 64.3257 57.27 63.5199 57.2708H52.5884Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M29.6457 76.0934H29.5648C29.3626 76.0934 29.1604 75.974 29.0795 75.7751L26.774 70.8813C26.592 70.4834 26.046 70.4635 25.8235 70.8216L22.7698 75.8149C22.6687 75.974 22.4867 76.0735 22.3047 76.0735H22.1227C21.8193 76.0735 21.5767 75.8348 21.5767 75.5364V63.9981C21.5767 63.6997 21.8193 63.4609 22.1227 63.4609H22.1631C22.4665 63.4609 22.7092 63.6997 22.7092 63.9981V71.8759C22.7092 72.4131 23.4372 72.612 23.7203 72.1544L28.8975 63.7395C28.9986 63.5803 29.1806 63.4808 29.3626 63.4808C29.7873 63.4808 30.0502 63.9384 29.8277 64.2965L27.2189 68.5537C27.1178 68.7128 27.1178 68.8919 27.1987 69.051L30.1513 75.3175C30.3131 75.6756 30.0502 76.0934 29.6457 76.0934Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M37.1282 74.9786C38.5438 74.9786 39.3123 74.0834 39.3123 72.4919V64.0371C39.3123 63.7387 39.555 63.5 39.8584 63.5H39.8988C40.2021 63.5 40.4448 63.7387 40.4448 64.0371V72.5516C40.4448 74.7797 39.1708 76.1523 37.108 76.1523H35.2677C33.2049 76.1523 31.9106 74.7797 31.9106 72.5516V64.0371C31.9106 63.7387 32.1533 63.5 32.4567 63.5H32.5173C32.8207 63.5 33.0634 63.7387 33.0634 64.0371V72.4919C33.0634 74.1033 33.8318 74.9786 35.2272 74.9786H37.1282Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M51.79 66.942C51.79 68.2748 51.2844 69.0109 50.8395 69.3889C50.5968 69.6077 50.5968 69.9658 50.8395 70.1846C51.2844 70.5626 51.79 71.2987 51.79 72.6315V72.6514C51.79 74.7602 50.4552 76.093 48.3318 76.093H43.276C42.9727 76.093 42.73 75.8543 42.73 75.5559V64.0176C42.73 63.7192 42.9727 63.4805 43.276 63.4805H48.3318C50.4552 63.4805 51.79 64.8133 51.79 66.942ZM48.2105 69.2098C49.7879 69.1899 50.6372 68.3743 50.6575 66.9221C50.6372 65.4499 49.7676 64.6343 48.1902 64.6343H44.3681C44.0647 64.6343 43.822 64.873 43.822 65.1714V74.4021C43.822 74.7005 44.0647 74.9392 44.3681 74.9392H48.1902C49.7676 74.9392 50.6372 74.1236 50.6575 72.6514C50.6372 71.1793 49.7879 70.3637 48.2105 70.3637H46.0668C45.7635 70.3637 45.5208 70.1249 45.5208 69.8265V69.747C45.5208 69.4486 45.7635 69.2098 46.0668 69.2098H48.2105Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M55.4706 64.0176V75.5559C55.4706 75.8543 55.2279 76.093 54.9246 76.093H54.8639C54.5605 76.093 54.3179 75.8543 54.3179 75.5559V64.0176C54.3179 63.7192 54.5605 63.4805 54.8639 63.4805H54.9246C55.2279 63.4805 55.4706 63.7192 55.4706 64.0176Z'
                    fill='#FCFCFC'
                />
                <path
                    d='M66.2702 76.0934H66.1893C65.9871 76.0934 65.7849 75.974 65.704 75.7751L63.3985 70.8813C63.2165 70.4834 62.6705 70.4635 62.448 70.8216L59.3943 75.8149C59.2932 75.974 59.1112 76.0735 58.9292 76.0735H58.7472C58.4439 76.0735 58.2012 75.8348 58.2012 75.5364V63.9981C58.2012 63.6997 58.4439 63.4609 58.7472 63.4609H58.7876C59.091 63.4609 59.3337 63.6997 59.3337 63.9981V71.8759C59.3337 72.4131 60.0617 72.612 60.3448 72.1544L65.522 63.7395C65.6231 63.5803 65.8051 63.4808 65.9871 63.4808C66.4118 63.4808 66.6747 63.9384 66.4522 64.2965L63.8839 68.5736C63.7828 68.7327 63.7828 68.9118 63.8637 69.0709L66.8163 75.3374C66.9376 75.6756 66.6747 76.0934 66.2702 76.0934Z'
                    fill='#FCFCFC'
                />
            </svg>
        </>
    );
};

export default Logotype;
