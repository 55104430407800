const PRODUCT_CAROUSEL_IMAGES_VARIANT: 'productCarouselImages' = 'productCarouselImages';
const KUBO_BG_BLUR_VARIANT: 'kuboBackgroundBlur' = 'kuboBackgroundBlur';
const PROFILE_BG_VARIANT: 'profileBackground' = 'profileBackground';
const AVATAR_S_48_VARIANT: 'avatar48' = 'avatar48';
const PUBLIC_VARIANT: 'public' = 'public';

const variantRegex = new RegExp(
    `${PRODUCT_CAROUSEL_IMAGES_VARIANT}|${KUBO_BG_BLUR_VARIANT}|${PROFILE_BG_VARIANT}|${PUBLIC_VARIANT}|${AVATAR_S_48_VARIANT}`,
    'g'
);

export const useGetImageVariant = () => {
    const getProfileBackgroundVariantImage = (imgPublicUrl: string | undefined) => {
        if (!imgPublicUrl) return;
        return imgPublicUrl.replace(variantRegex, PROFILE_BG_VARIANT);
    };

    const getProductCarouselImagesVariantImage = (imgPublicUrl: string) => {
        return imgPublicUrl.replace(variantRegex, PRODUCT_CAROUSEL_IMAGES_VARIANT);
    };

    const getKuboBackgroundBlurVariantImage = (imgPublicUrl: string | undefined) => {
        if (!imgPublicUrl) return;
        return imgPublicUrl.replace(variantRegex, KUBO_BG_BLUR_VARIANT);
    };

    const getPublicVariantImage = (imgPublicUrl: string) => {
        return imgPublicUrl.replace(variantRegex, PUBLIC_VARIANT);
    };

    const getAvatarVariantImage = (imgPublicUrl: string) => {
        return imgPublicUrl.replace(variantRegex, AVATAR_S_48_VARIANT);
    };

    return {
        getProfileBackgroundVariantImage,
        getPublicVariantImage,
        getAvatarVariantImage,
        getKuboBackgroundBlurVariantImage,
        getProductCarouselImagesVariantImage
    };
};
