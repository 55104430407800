import {
    MarketingPrice,
    Product,
    PRODUCT_TYPE,
    ProductBase,
    Vendor
} from 'framework/api/models/product';
import { TYPE_DATA_INVITATION } from 'framework/api/models/invitation';
import { clearCloudfareUrl } from 'framework/utils/cloudfare-images-sign';
import { validateProperties } from 'framework/api/dto/utils';
import { isOfTypeEnum } from 'framework/utils/type-check';
import { deleteCentsUnnecessary } from 'framework/utils/money';

export const transformImagesProduct = (imagesDto: any): string[] => {
    if (!imagesDto || !Array.isArray(imagesDto)) return [];
    return imagesDto.map(image => clearCloudfareUrl(image));
};

function createProductBase(dtoProduct: any): ProductBase {
    return {
        typeData: TYPE_DATA_INVITATION.PRODUCT,
        id: dtoProduct.id,
        expirationDate: !dtoProduct.expirationDate
            ? undefined
            : new Date(dtoProduct.expirationDate),
        stock: dtoProduct.stock,
        totalStock: dtoProduct.totalStock,
        isPublished: dtoProduct.isPublished,
        name: dtoProduct.name,
        price: dtoProduct.priceAmount / 100,
        priceCents: dtoProduct.priceAmount,
        imagesUrL: transformImagesProduct(dtoProduct.images),
        info: dtoProduct.info || '',
        description: dtoProduct.description || '',
        status: dtoProduct.status,
        pageUrl: dtoProduct.pageUrl || '',
        marketingTags: dtoProduct.marketingTags || [],
        vendor: validateVendor(dtoProduct.vendor),
        backgroundImgUrl: clearCloudfareUrl(dtoProduct.backgroundImgUrl),
        backgroundVideoUrl: clearCloudfareUrl(dtoProduct.backgroundVideoUrl),
        marketingPrice: validMarketingPriceView(dtoProduct.marketingPriceView),
        voucherRedeemUrl: dtoProduct.voucherRedeemUrl,
        voucherRedeemInfo: dtoProduct.voucherRedeemInfo
    };
}

export const validateProduct = (dto: any): Product => {
    const dtoProduct = 'product' in dto ? dto.product : dto;

    const baseProps = [
        'id',
        'type',
        'expirationDate',
        'stock',
        'totalStock',
        'name',
        'marketingPriceView',
        'status',
        'vendor',
        'isPublished',
        'priceAmountView',
        'priceAmount'
    ];

    validateProperties(dtoProduct, baseProps);

    if (!isOfTypeEnum<PRODUCT_TYPE>(PRODUCT_TYPE, dtoProduct.type)) {
        throw new Error('Structure Product object invalid');
    }

    const productBase = createProductBase(dtoProduct);

    switch (dtoProduct.type) {
        case PRODUCT_TYPE.CLOSED:
            if ('members' in dtoProduct || 'Members' in dtoProduct) {
                return {
                    ...productBase,
                    productType: PRODUCT_TYPE.CLOSED,
                    members: dtoProduct.Members || dtoProduct.members,
                    minMembers: dtoProduct.Members || dtoProduct.members,
                    maxMembers: dtoProduct.Members || dtoProduct.members
                };
            }

            throw new Error(`Property members missing in object`);
        case PRODUCT_TYPE.VARIABLE:
            validateProperties(dtoProduct, ['suggestedMinMembers', 'suggestedMaxMembers']);
            return {
                ...productBase,
                productType: PRODUCT_TYPE.VARIABLE,
                suggestedMinMembers: dtoProduct.suggestedMinMembers,
                suggestedMaxMembers: dtoProduct.suggestedMaxMembers,
                minMembers: dtoProduct.suggestedMinMembers,
                maxMembers: dtoProduct.suggestedMaxMembers
            };
        default:
            throw new Error('Invalid product type');
    }
};

const validMarketingPriceView = (marketingPriceView: any): MarketingPrice[] => {
    if (!Array.isArray(marketingPriceView)) {
        throw new Error('Invalid marketingPriceView');
    }
    return marketingPriceView.reduce((acc: MarketingPrice[], item) => {
        if ('people' in item && 'priceView' in item) {
            acc.push({ members: item.people, priceView: deleteCentsUnnecessary(item.priceView) });
        }
        return acc;
    }, []);
};

export const validateVendor = (dtoVendor: any): Vendor => {
    validateProperties(dtoVendor, ['name']);
    return {
        name: dtoVendor.name,
        termOfServicesUrl: dtoVendor.termOfServicesUrl || '',
        logo: clearCloudfareUrl(dtoVendor.logo)
    };
};
