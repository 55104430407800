import { Box } from '@mui/material';
import { useStyles } from './app-layout.style';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const AppLayout = () => {
    const { classes: styles } = useStyles();

    return (
        <Box data-testid={'app-layout'} className={styles.containerLayout}>
            <Box component={'main'} className={styles.containerOutlet}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default AppLayout;
