import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    containerLayout: {
        display: 'flex',
        padding: 0,
        margin: 0,
        minHeight: '-webkit-fill-available',
        background: theme.palette.common.grey.c100
    },
    containerOutlet: {
        flexGrow: 1,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    }
}));
