import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useStyles } from 'features/components/cost-types/cost-types-item/cost-types-item.style';
import { useGlobalStyles } from '../../../../GlobalStyles.style';

type CostTypesItemProps = { amount: number; quantity: string; text?: string };

const CostTypesItem = (props: CostTypesItemProps) => {
    const { classes: styles } = useStyles();
    const { classes: globalStyles } = useGlobalStyles();

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box className={styles.containerPersons}>
                <PersonIcon fontSize={'medium'} />
                <Typography color={'common.whiteC.c100'} variant={'DisplayMini'}>
                    {props.amount}
                </Typography>
            </Box>
            <Box display={'flex'} gap={16}>
                <Typography
                    className={globalStyles.fillWhiteText}
                    color={'common.grey.c100'}
                    variant={'DisplayMini'}
                >
                    {props.quantity}
                </Typography>

                {!!props.text && (
                    <Typography
                        style={{ maxWidth: 72 }}
                        color={'common.whiteC.c100'}
                        variant={'body10Bold'}
                    >
                        {props.text}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default CostTypesItem;
