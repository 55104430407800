import { typographys } from './typography';
import { Breakpoint } from '@mui/material';

export const theme = {
    palette: {
        primary: {
            main: 'rgba(66, 63, 255, 1)',
            light: '#D8E8EB'
        },
        secondary: {
            main: 'rgba(252, 252, 252, 0.16)',
            light: '#D8E8EB'
        },
        text: {
            primary: '#000000',
            secondary: '#074A56',
            input: '#495057'
        },
        grey: {
            input: ''
        },
        background: {
            paper: '#F8F9FA',
            default: '#F8F9FA'
        },
        error: {
            main: 'rgba(191, 61, 53, 1)'
        },
        success: {
            main: 'rgba(32, 188, 86, 1)'
        },
        warning: {
            main: 'rgba(193, 205, 57, 1)'
        },
        info: {
            main: '#1C7ED6'
        },
        common: {
            black: '#000',
            white: '#FFF',
            transparent: 'transparent',
            red: '#7D7D7D',
            green: '#009B57',
            orange: '#F05050',
            blue: '#413DFF',
            light_blue: '#81B0FF',
            grey: {
                c100: 'rgba(26, 27, 39, 1)',
                c100_80: 'rgba(26, 27, 39, 0.8)',
                c100_60: 'rgba(34, 36, 51, 0.8)',
                c100_40: 'rgba(34, 36, 51, 0.4)',
                c60: '#222433',
                c60_80: '#494b57'
            },
            overlay: {
                c100: 'rgba(21, 22, 30, 1)',
                c100_80: 'rgba(21, 22, 30, 0.8)',
                c100_60: 'rgba(21, 22, 30, 0.6)',
                c100_40: 'rgba(21, 22, 30, 0.4)',
                c100_20: 'rgba(21, 22, 30, 0.2)',
                c100_0: 'rgba(21, 22, 30, 0)'
            },
            whiteC: {
                c100: 'rgba(252, 252, 252, 1)',
                c100_70: 'rgba(255, 255, 255, 0.7)',
                c100_64: 'rgba(252, 252, 252, 0.64)',
                c100_40: 'rgba(252, 252, 252, 0.4)',
                c100_24: 'rgba(252, 252, 252, 0.24)',
                c100_16: 'rgba(252, 252, 252, 0.16)',
                c100_08: 'rgba(252, 252, 252, 0.08)',
                c100_04: 'rgba(252, 252, 252, 0.04)'
            },
            primary: {
                c100: 'rgba(66, 63, 255, 1)',
                c100_50: 'rgba(74, 90, 255, 1)',
                c100_40: 'rgba(74, 90, 255, 0.4)',
                c100_24: 'rgba(74, 90, 255, 0.24)',
                c100_16: 'rgba(74, 90, 255, 0.16)',
                c100_08: 'rgba(74, 90, 255, 0.08)'
            },
            secondary: {
                c100: 'rgba(191, 61, 53, 1)',
                c100_50: 'rgba(255, 112, 98, 1)',
                c100_40: 'rgba(255, 85, 74, 0.4)',
                c100_24: 'rgba(255, 85, 74, 0.24)',
                c100_16: 'rgba(255, 85, 74, 0.16)',
                c100_08: 'rgba(255, 85, 74, 0.08)'
            },
            gradient: {
                c100_gradient_1_blue: 'rgba(65, 61, 255, 1)',
                c100_gradient_2_blue: 'rgba(84, 122, 255, 1)',
                c100_gradient_1_red: 'rgba(255, 61, 61, 1)',
                c100_gradient_2_red: 'rgba(255, 112, 98, 1)',
                c100_gradient_1_light_green: 'rgba(74, 255, 135, 0)',
                c100_gradient_2_light_green: 'rgba(74, 255, 135, 0.16)',
                c100_gradient_1_light_red: 'rgba(255, 74, 74, 0)',
                c100_gradient_2_light_red: 'rgba(255, 74, 74, 0.16)',
                c100_gradient_1_light_blue: 'rgba(74, 90, 255, 0)',
                c100_gradient_2_light_blue: 'rgba(74, 90, 255, 0.16)',
                c100_gradient_1_light_black: 'rgba(26, 27, 39, 0)',
                c100_gradient_2_light_black: 'rgba(26, 27, 39, 0.16)',
                c100_gradient_1_light_white: 'rgba(252, 252, 252, 0)',
                c100_gradient_2_light_white: 'rgba(252, 252, 252, 0.16)'
            },
            alert: {
                c100: '#BF3D35',
                c100_50: '#FF7062',
                c100_40: 'rgba(255, 85, 74, 0.4)',
                c100_16: 'rgba(255, 85, 74, 0.16)'
            },
            success: {
                c100: 'rgba(32, 188, 86, 1)',
                c100_50: 'rgba(47, 221, 127, 1)',
                c100_40: 'rgba(47, 221, 127, 0.4)',
                c100_16: 'rgba(47, 221, 127, 0.16)'
            },
            warning: {
                c100: 'rgba(193, 205, 57, 1)',
                c100_50: 'rgba(241, 255, 74, 1)',
                c100_40: 'rgba(240, 255, 74, 0.4)',
                c100_16: 'rgba(240, 255, 74, 0.16)'
            }
        },
        divider: '#DEE2E6'
    },
    breakpoints: {
        keys: ['sm', 'md', 'lg'] as Breakpoint[],
        values: {
            sm: 600,
            md: 900,
            lg: 1200
        }
    },
    zIndex: {
        header: 99
    },
    typography: typographys,
    spacing: 1
};
