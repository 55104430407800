import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useGlobalStyles = makeStyles()((_theme: Theme) => ({
    cursorPointer: {
        cursor: 'pointer'
    },
    underline: {
        textDecoration: 'underline'
    },
    containerInvites: {
        maxWidth: 790,
        width: '100%'
    },
    containerIconModals: {
        display: 'flex',
        width: 72,
        height: 72,
        padding: 16,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 99
    },
    inputDashed: {
        backgroundColor: 'transparent',
        letterSpacing: 7.2,
        fontWeight: 500,
        fontSize: 24,
        '&.MuiInputBase-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0
            }
        },

        '& .MuiInputBase-input': {
            textAlign: 'center'
        }
    },
    fillWhiteText: {
        background: 'white',
        padding: '0 4px 0 4px',
        height: 'fit-content',
        width: 'fit-content'
    }
}));
