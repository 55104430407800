import { theme } from './theme';

const LINE_HEIGHT_MULTIPLIER = 1.5;

export const appTypographys = {
    fontFamily: theme.typography.fontFamily,
    htmlFontSize: theme.typography.htmlFontSize,
    fontWeightRegular: theme.typography.fontWeightRegular,
    fontWeightMedium: theme.typography.fontWeightMedium,
    fontWeightBold: theme.typography.fontWeightBold,
    DisplayLarge: {
        fontFamily: theme.typography.DisplayLarge.fontFamily,
        fontSize: theme.typography.DisplayLarge.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.DisplayLarge.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    DisplayMedium: {
        fontFamily: theme.typography.DisplayMedium.fontFamily,
        fontSize: theme.typography.DisplayMedium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.DisplayMedium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    DisplaySmall: {
        fontFamily: theme.typography.DisplaySmall.fontFamily,
        fontSize: theme.typography.DisplaySmall.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.DisplaySmall.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    DisplaySuperSmall: {
        fontFamily: theme.typography.DisplaySuperSmall.fontFamily,
        fontSize: theme.typography.DisplaySuperSmall.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.DisplaySuperSmall.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    DisplayMini: {
        fontFamily: theme.typography.DisplayMini.fontFamily,
        fontSize: theme.typography.DisplayMini.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.DisplayMini.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    Heading1: {
        fontFamily: theme.typography.Heading1.fontFamily,
        fontSize: theme.typography.Heading1.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.Heading1.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    Heading2: {
        fontFamily: theme.typography.Heading2.fontFamily,
        fontSize: theme.typography.Heading2.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.Heading2.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    Heading3: {
        fontFamily: theme.typography.Heading3.fontFamily,
        fontSize: theme.typography.Heading3.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.Heading3.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    Heading4: {
        fontFamily: theme.typography.Heading4.fontFamily,
        fontSize: theme.typography.Heading4.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.Heading4.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body18Bold: {
        fontFamily: theme.typography.body18Bold.fontFamily,
        fontSize: theme.typography.body18Bold.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body18Bold.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body18Medium: {
        fontFamily: theme.typography.body18Medium.fontFamily,
        fontSize: theme.typography.body18Medium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body18Medium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body18Regular: {
        fontFamily: theme.typography.body18Regular.fontFamily,
        fontSize: theme.typography.body18Regular.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body18Regular.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body16Medium: {
        fontFamily: theme.typography.body16Medium.fontFamily,
        fontSize: theme.typography.body16Medium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body16Medium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body16Regular: {
        fontFamily: theme.typography.body16Regular.fontFamily,
        fontSize: theme.typography.body16Regular.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body16Regular.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body16Bold: {
        fontFamily: theme.typography.body16Bold.fontFamily,
        fontSize: theme.typography.body16Bold.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body16Bold.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body14Medium: {
        fontFamily: theme.typography.body14Medium.fontFamily,
        fontSize: theme.typography.body14Medium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body14Medium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body14Regular: {
        fontFamily: theme.typography.body14Regular.fontFamily,
        fontSize: theme.typography.body14Regular.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body14Regular.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body14Bold: {
        fontFamily: theme.typography.body14Bold.fontFamily,
        fontSize: theme.typography.body14Bold.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body14Bold.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body12Medium: {
        fontFamily: theme.typography.body12Medium.fontFamily,
        fontSize: theme.typography.body12Medium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body12Medium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body12Regular: {
        fontFamily: theme.typography.body12Regular.fontFamily,
        fontSize: theme.typography.body12Regular.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body12Regular.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body12Bold: {
        fontFamily: theme.typography.body12Bold.fontFamily,
        fontSize: theme.typography.body12Bold.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body12Bold.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body10Medium: {
        fontFamily: theme.typography.body10Medium.fontFamily,
        fontSize: theme.typography.body10Medium.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body10Medium.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body10Regular: {
        fontFamily: theme.typography.body10Regular.fontFamily,
        fontSize: theme.typography.body10Regular.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body10Regular.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    },
    body10Bold: {
        fontFamily: theme.typography.body10Bold.fontFamily,
        fontSize: theme.typography.body10Bold.fontSize + 'rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: theme.typography.body10Bold.fontSize * LINE_HEIGHT_MULTIPLIER + 'rem'
    }
};
