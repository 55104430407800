import { getKubo } from './services/kubo/kubo-request';
import { myAWSCall, ResponseAWSCustom } from './aws/aws';
import { getAuth } from './services/auth/auth-request';
import { SESSION_STORAGE_ACCESS_TOKEN } from 'framework/constants/app';
import { HTTPError } from '../utils/http-erros';
import { getInvitations } from 'framework/api/services/invitation/invitation-request';

export type MyApiType = ReturnType<typeof buildMyApi>;

export type API_METHODS = 'GET' | 'PUT' | 'POST' | 'DELETE';

export type MyApiRequest = (
    url: string,
    method: API_METHODS,
    body?: Object
) => Promise<ResponseAWSCustom>;

export function buildMyApi(closeSession: () => void) {
    let myApiHeaders: any = {
        'Content-Type': 'application/json',
        'Accept-Language': 'es-ES'
    };

    const addAuthToHeaders = (token: string) => {
        myApiHeaders = {
            'Content-Type': 'application/json',
            'Accept-Language': 'es-ES',
            Authorization: `Bearer ${token}`
        };
    };

    async function request(url: string, method: API_METHODS, body?: Object) {
        const jsonValue = sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
        addAuthToHeaders(jsonValue || '');

        // POST FOR AWS
        let response = await myAWSCall(url, 'POST', myApiHeaders, body);

        if (response.statusCode === 401) {
            closeSession();
        }

        if (response.statusCode < 200 || response.statusCode >= 300) {
            const bodyParse = JSON.parse(response.body);
            const errorMessage = !!bodyParse && !!bodyParse.error ? bodyParse.error : 'error';
            throw new HTTPError(response.statusCode, errorMessage);
        }

        return response;
    }

    function auth() {
        return getAuth(request);
    }

    function kubo() {
        return getKubo(request);
    }

    function invitations() {
        return getInvitations(request);
    }

    return { kubo, auth, invitations };
}
