import React, { useState } from 'react';
import { Box, Divider, Drawer } from '@mui/material';
import { Kubo } from 'framework/api/models/kubo';
import { useStyles } from './kubo-invitation-info.style';
import { Product } from 'framework/api/models/product';
import { TYPE_DATA_INVITATION } from 'framework/api/models/invitation';
import ProductInfo from 'features/invitation/components/kubo-invitation-info/components/product-info';
import KuboInfo from 'features/invitation/components/kubo-invitation-info/components/kubo-info';

type KuboCardInvitationProps = {
    itemInfo: Product | Kubo;
};

const KuboInvitationInfo = (props: KuboCardInvitationProps) => {
    const { classes: styles } = useStyles();
    const [stateShowMoreDrawer, setStateShowMoreDrawer] = useState(false);

    const onClickShowMore = () => {
        setStateShowMoreDrawer(true);
    };

    return (
        <Box className={styles.container}>
            {props.itemInfo.typeData === TYPE_DATA_INVITATION.PRODUCT ? (
                <ProductInfo onClickShowMore={onClickShowMore} product={props.itemInfo} />
            ) : (
                <KuboInfo onClickShowMore={onClickShowMore} kubo={props.itemInfo} />
            )}

            <Divider className={styles.dividerMinMax} />

            <Drawer
                anchor={'bottom'}
                open={stateShowMoreDrawer}
                onClose={() => {
                    setStateShowMoreDrawer(false);
                }}
                classes={{ paper: styles.showMoreInfoDrawerPaper }}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            props.itemInfo.typeData === TYPE_DATA_INVITATION.KUBO
                                ? props.itemInfo.product.info
                                : props.itemInfo.info
                    }}
                />
            </Drawer>
        </Box>
    );
};

export default KuboInvitationInfo;
