export const user = {
    index: 'user'
};

export const invitation = {
    index: 'invitation'
};

export const getUserKey = () => {
    return [user.index];
};

export const getInvitationById = (id: string) => {
    return [invitation.index, id];
};
