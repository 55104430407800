import { MyApiRequest } from 'framework/api/my-api';
import { PreAcceptInvitationBody } from 'framework/api/models/invitation';
import { validateResponseUpdate } from 'framework/api/dto/common';
import { validGetInvitationById } from 'framework/api/dto/invitation/dto-get-invitation-by-id';

const URL_BUCKET_INVITATION = process.env.REACT_APP_BUCKET_INVITATION_URL || '';

export function getInvitations(request: MyApiRequest) {
    async function preAcceptInvitation(invitationId: string) {
        const body: PreAcceptInvitationBody = {
            invitation: invitationId
        };

        const response = await request('kubo_pre_accept_invitation', 'POST', body);

        return validateResponseUpdate(JSON.parse(response.body));
    }

    async function getInvitationByIdS3(invitationId: string) {
        if ('invitationData' in window) {
            return validGetInvitationById(window.invitationData);
        }

        const response = await fetch(URL_BUCKET_INVITATION + invitationId + '.json', {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const invitationData = await response.json();

        return validGetInvitationById(invitationData);
    }

    return {
        preAcceptInvitation,
        getInvitationByIdS3
    };
}
