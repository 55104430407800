import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { STYLES, useStyles } from 'framework/web/shared/shared-avatar/shared-avatar.style';
import PersonIcon from '@mui/icons-material/Person';
import { useSignedImage } from 'framework/utils/hooks/signed-image';
import { useGetImageVariant } from 'framework/utils/hooks/use-get-image-variant';

export const enum TypeAvatarSize {
    s48 = 's48',
    s40 = 's40',
    s24 = 's24'
}

type SharedAvatarProps = {
    size?: TypeAvatarSize;
    showLabel?: boolean;
    alias?: string;
    image?: string;
    style?: string;
    objectFit?: 'contain' | 'cover';
};

const SharedAvatar = (props: SharedAvatarProps) => {
    const {
        size = TypeAvatarSize.s40,
        showLabel = size === TypeAvatarSize.s24,
        image,
        alias,
        style,
        objectFit = 'contain'
    } = props;
    const { imageSigned, signedImage } = useSignedImage();
    const { getAvatarVariantImage } = useGetImageVariant();

    useEffect(() => {
        if (!image) return;
        signedImage(getAvatarVariantImage(image));
    }, []);

    const { classes: styles, cx } = useStyles({ size: size });

    return (
        <Box
            display={'flex'}
            zIndex={1}
            flexDirection={showLabel ? 'column' : 'row'}
            className={style}
        >
            {!!imageSigned ? (
                <img
                    style={{
                        width: STYLES[size].containerWidth,
                        height: STYLES[size].containerHeight,
                        borderRadius: STYLES[size].containerBorderRadius,
                        objectFit: objectFit
                    }}
                    src={imageSigned}
                    loading='lazy'
                    alt={'shared-avatar'}
                />
            ) : alias ? (
                <Box className={styles.containerFirstLetter}>
                    <Typography
                        className={styles.firstLetter}
                        color={'common.whiteC.c100'}
                        variant={size === 's24' ? 'body10Bold' : 'body12Bold'}
                    >
                        {alias[0]}
                    </Typography>
                </Box>
            ) : (
                <Box className={styles.containerIcon}>
                    <PersonIcon fontSize={STYLES[size].iconSize} />
                </Box>
            )}
            {showLabel && !!alias && (
                <Typography
                    className={cx(styles.text, styles.textAlias)}
                    color={'common.whiteC.c100'}
                    variant={'body10Bold'}
                >
                    {alias}
                </Typography>
            )}
        </Box>
    );
};
export default SharedAvatar;
