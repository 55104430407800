export enum SizeIconType {
    s16 = 'small',
    s24 = 'medium',
    s32 = 'large'
}

export const SIZE_ICON = {
    small: { fontSize: 16 },
    medium: { fontSize: 24 },
    large: { fontSize: 32 },
    extraLarge: { fontSize: 44 }
};

export const SIZE_ICON_BUTTON = {
    small: { width: 35, height: 35 },
    medium: { width: 40, height: 40 },
    large: { width: 50, height: 50 }
};
