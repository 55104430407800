import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export const USER_INVITES_STORAGE_KEY = 'USER_STORAGE_KEY';

export type AuthInvites = {
    isLoggedIn: boolean;
    phone: string;
    alias?: string;
    imageBase64?: string;
    isLoadingData?: boolean;
    userId: string;
    TOS_accept?: boolean;
};

type AuthInvitesContext = [AuthInvites, (auth: AuthInvites) => void];

const defaultAuth: AuthInvites = {
    phone: '',
    isLoggedIn: false,
    isLoadingData: false,
    userId: '',
    TOS_accept: false
};

const authTest: AuthInvites = {
    phone: '',
    isLoggedIn: true,
    isLoadingData: true,
    userId: '',
    TOS_accept: false
};

const defaultAuthContext: AuthInvitesContext = [defaultAuth, () => undefined];

export const MyAuthInvitesContext = createContext<AuthInvitesContext>(defaultAuthContext);

export type MyAuthProviderProps = {
    test: boolean;
};

export const MyAuthInvitesProvider: React.FC<
    React.PropsWithChildren<MyAuthProviderProps>
> = props => {
    const [auth, setAuthState] = useState<AuthInvites>(props.test ? authTest : defaultAuth);

    useEffect(() => {
        const jsonValue = sessionStorage.getItem(USER_INVITES_STORAGE_KEY);
        const valueParse = jsonValue != null ? JSON.parse(jsonValue) : null;
        if (!valueParse) {
            setAuthState({
                phone: '',
                isLoggedIn: false,
                isLoadingData: false,
                userId: '',
                TOS_accept: false
            });
            return;
        }
        if ('userId' in valueParse) {
            setAuthState(valueParse as AuthInvites);
        } else {
            setAuthState({
                phone: '',
                isLoggedIn: false,
                isLoadingData: false,
                userId: '',
                TOS_accept: false
            });
        }
    }, []);

    const setAuth = (newValue: AuthInvites) => {
        setAuthState(newValue);
        window.sessionStorage.setItem(USER_INVITES_STORAGE_KEY, JSON.stringify(newValue));
    };

    return <MyAuthInvitesContext.Provider value={[auth, setAuth]} {...props} />;
};

export function useAuthInvites() {
    return useContext(MyAuthInvitesContext);
}
