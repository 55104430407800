export const typographys = {
    fontFamily: 'Kanit, Catamaran, -apple-system, system-ui',
    htmlFontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    DisplayLarge: {
        fontFamily: 'Kanit',
        fontSize: 3.5
    },
    DisplayMedium: {
        fontFamily: 'Kanit',
        fontSize: 2.5
    },
    DisplaySmall: {
        fontFamily: 'Kanit',
        fontSize: 2
    },
    DisplaySuperSmall: {
        fontFamily: 'Kanit',
        fontSize: 1.5
    },
    DisplayMini: {
        fontFamily: 'Kanit',
        fontSize: 1.3125
    },
    Heading1: {
        fontFamily: 'Kanit',
        fontSize: 1.5
    },
    Heading2: {
        fontFamily: 'Kanit',
        fontSize: 1.125
    },
    Heading3: {
        fontFamily: 'Kanit',
        fontSize: 1
    },
    Heading4: {
        fontFamily: 'Kanit',
        fontSize: 0.875
    },
    body18Bold: {
        fontFamily: 'Catamaran',
        fontSize: 1.125
    },
    body18Medium: {
        fontFamily: 'Catamaran',
        fontSize: 1.125
    },
    body18Regular: {
        fontFamily: 'Catamaran',
        fontSize: 1.125
    },
    body16Medium: {
        fontFamily: 'Catamaran',
        fontSize: 1
    },
    body16Regular: {
        fontFamily: 'Catamaran',
        fontSize: 1
    },
    body16Bold: {
        fontFamily: 'Catamaran',
        fontSize: 1
    },
    body14Medium: {
        fontFamily: 'Catamaran',
        fontSize: 0.875
    },
    body14Regular: {
        fontFamily: 'Catamaran',
        fontSize: 0.875
    },
    body14Bold: {
        fontFamily: 'Catamaran',
        fontSize: 0.875
    },
    body12Medium: {
        fontFamily: 'Catamaran',
        fontSize: 0.75
    },
    body12Regular: {
        fontFamily: 'Catamaran',
        fontSize: 0.75
    },
    body12Bold: {
        fontFamily: 'Catamaran',
        fontSize: 0.75
    },
    body10Medium: {
        fontFamily: 'Catamaran',
        fontSize: 0.625
    },
    body10Regular: {
        fontFamily: 'Catamaran',
        fontSize: 0.625
    },
    body10Bold: {
        fontFamily: 'Catamaran',
        fontSize: 0.625
    }
};
