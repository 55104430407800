import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        padding: 16,
        paddingTop: 56,
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 70,
            padding: 16
        }
    },
    containerLogo: {
        display: 'flex',
        gap: 32,
        marginBottom: 66,
        [theme.breakpoints.down('md')]: {
            gap: 16,
            marginBottom: 42
        }
    },
    containerData: {
        display: 'flex',
        gap: 32,
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 26
        },
        [theme.breakpoints.down('sm')]: {
            gap: 0
        }
    },
    imgContainer: {
        position: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
        zIndex: 0
    },
    overlay: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        height: '100%',
        background: `linear-gradient(0deg, rgba(21, 22, 30, 0.80) 0%, rgba(21, 22, 30, 0.80) 100%)`
    }
}));
