import * as React from 'react';
import { createContext, useContext } from 'react';
import { buildMyApi, MyApiType } from './my-api';
import { useCloseSession } from '../utils/hooks/session';

const defaultApiContext: MyApiType = buildMyApi(() => {});

export const MyApiContext = createContext<MyApiType>(defaultApiContext);

export const MyApiProvider: React.FC<React.PropsWithChildren<{}>> = props => {
    const { closeSession } = useCloseSession();

    const api = buildMyApi(closeSession);

    return <MyApiContext.Provider value={api} {...props} />;
};

export function useMyApi() {
    return useContext(MyApiContext);
}
