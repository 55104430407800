import { Kubo, KUBO_TYPES, Members, Owner } from 'framework/api/models/kubo';
import { TYPE_DATA_INVITATION } from 'framework/api/models/invitation';
import { clearCloudfareUrl } from 'framework/utils/cloudfare-images-sign';
import { validateProduct } from 'framework/api/dto/product/common';
import { deleteCentsUnnecessary } from 'framework/utils/money';

export const validKubo = (dto: any): Kubo => {
    if (
        'id' in dto &&
        'product' in dto &&
        'title' in dto &&
        'type' in dto &&
        'maxMembers' in dto &&
        'minMemberCostView' in dto &&
        'maxMemberCostView' in dto &&
        'owner' in dto
    ) {
        return {
            typeData: TYPE_DATA_INVITATION.KUBO,
            kuboId: dto.id,
            title: dto.title,
            description: dto.description || '',
            type: dto.type as KUBO_TYPES,
            minMembers: dto.minMembers || 0,
            maxMembers: dto.maxMembers,
            ownerInfo: validDtoOwner(dto.owner),
            membersInfo: validDtoMembers(dto.members),
            productPriceAmount: dto.productPriceAmount || 0,
            productId: dto.productID || '',
            product: validateProduct(dto.product),
            minMemberCostView: deleteCentsUnnecessary(dto.minMemberCostView),
            maxMemberCostView: deleteCentsUnnecessary(dto.maxMemberCostView)
        };
    }

    throw new Error('struct kubo invitation invalid');
};

export const validDtoMembers = (dtoMembers: any): Members[] => {
    if (!dtoMembers || !Array.isArray(dtoMembers)) return [];
    const members: Members[] = [];
    dtoMembers.forEach(member => {
        const memberValid = validDtoMember(member);
        !!memberValid && members.push(memberValid);
    });

    return members;
};

export const validDtoMember = (dtoMember: any): Members | undefined => {
    if ('id' in dtoMember) {
        return {
            id: dtoMember.id,
            alias: dtoMember.alias || '',
            imageUrl: clearCloudfareUrl(dtoMember.imageURL)
        };
    }

    return undefined;
};

export const validDtoOwner = (dtoOwner: any): Owner => {
    if ('id' in dtoOwner) {
        return {
            ownerId: dtoOwner.id,
            alias: dtoOwner.alias || '',
            imageUrl: clearCloudfareUrl(dtoOwner.imageURL)
        };
    }
    throw new Error('Structure owner object invalid');
};
