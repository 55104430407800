import { RoutesType } from 'routing/framework/routes/index';
import { RouteN } from 'routing/routes-path';
import React, { lazy } from 'react';

import { MyAuthInvitesProvider } from 'framework/providers/auth-invites-provider';
import InvitationLayout from 'features/invitation/invitation-layout';
import SmsGuard from 'routing/framework/guards/sms-guard';
import SingInGuard from 'routing/framework/guards/signin-guard';
import { Navigate } from 'react-router-dom';

const SignIn = lazy(() => import('features/sign-in/sign-in'));
const SmsVerification = lazy(() => import('features/sms-verification/sms-verification'));
const Successfully = lazy(() => import('features/invitation/successfully/successfully'));
const Rejected = lazy(() => import('features/invitation/rejected/rejected'));
const Redeem = lazy(() => import('features/redeem/redeem'));

export const invitesRoutes: RoutesType[] = [
    {
        path: RouteN.invites.route,
        id: 'invites',
        suspense: false,
        element: (
            <MyAuthInvitesProvider test={false}>
                <InvitationLayout />
            </MyAuthInvitesProvider>
        ),
        children: [
            {
                path: '',
                id: 'invitation-sign-in-navigate',
                suspense: true,
                element: (
                    <SingInGuard>
                        <SignIn />
                    </SingInGuard>
                )
            },
            {
                path: RouteN.invites.children.smsVerification,
                id: 'sms-verification',
                suspense: true,
                element: (
                    <SmsGuard>
                        <SmsVerification />
                    </SmsGuard>
                )
            },
            {
                id: 'error-404',
                path: '*',
                suspense: true,
                element: <Navigate to={RouteN.error404.route} replace={true} />
            }
        ]
    },
    {
        path: RouteN.inviteRejected.route,
        id: 'invitation-rejected',
        suspense: true,
        element: <InvitationLayout showKuboInvitation={false} />,
        children: [
            {
                path: '',
                id: 'rejected-children',
                suspense: true,
                element: <Rejected />
            }
        ]
    },
    {
        path: RouteN.inviteSuccessfully.route,
        id: 'invitation-successfully',
        suspense: false,
        element: <InvitationLayout showKuboInvitation={false} />,
        children: [
            {
                path: '',
                id: 'successfully-children',
                suspense: true,
                element: <Successfully />
            }
        ]
    },
    {
        path: RouteN.redeem.route,
        id: 'invitation-redeem',
        suspense: true,
        element: <Redeem />
    }
];
