import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { Box, Typography } from '@mui/material';
import { KUBO_TYPES, Members } from 'framework/api/models/kubo';
import SharedAvatar, { TypeAvatarSize } from 'framework/web/shared/shared-avatar/shared-avatar';
import { addZerosLeft } from 'framework/utils/kubo';
import { useI18nService } from 'framework/i18n/i18n-service';
import { useStyles } from './kubo-members-slide.style';

type KuboMembersSlideProps = {
    maxMembersIntoKubo: number;
    kuboMembers: Members[];
    kuboType: KUBO_TYPES;
    showAlias?: boolean;
    showHeader?: boolean;
};

const KuboMembersSlide = (props: KuboMembersSlideProps) => {
    const { showHeader = true } = props;
    const { t } = useI18nService();
    const { classes: styles } = useStyles();

    const fillMembersAvatars = (): Members[] => {
        const max = !!props.maxMembersIntoKubo
            ? props.maxMembersIntoKubo - props.kuboMembers.length
            : 0;

        const filled = Array.from({ length: max }, (_, index) => ({
            id: addZerosLeft(index),
            alias: '',
            imageUrl: ''
        }));

        return [...props.kuboMembers, ...filled];
    };

    const getLabelTotalMembers = () => {
        if (
            props.kuboMembers.length === props.maxMembersIntoKubo ||
            props.kuboMembers.length > props.maxMembersIntoKubo
        )
            return t('membersOfComplete');

        if (props.kuboMembers.length === 1) return t('memberOf', { max: props.maxMembersIntoKubo });
        if (props.kuboMembers.length >= 0) return t('membersOf', { max: props.maxMembersIntoKubo });
    };

    return (
        <Box className={styles.containerInvitation}>
            {showHeader && (
                <Box className={styles.containerLabel}>
                    <Box className={styles.containerCurrentMembers}>
                        <Typography
                            className={styles.textCurrentMembers}
                            color={'common.whiteC.c100_64'}
                            variant={'body10Bold'}
                        >
                            {props.kuboMembers.length}
                        </Typography>
                    </Box>

                    <Typography color={'common.whiteC.c100_64'} variant={'body12Medium'}>
                        {getLabelTotalMembers()}
                    </Typography>
                </Box>
            )}
            <Swiper spaceBetween={8} slidesPerView={'auto'} direction={'horizontal'}>
                {fillMembersAvatars().map((m, i) => (
                    <SwiperSlide key={i} style={{ width: 40 }}>
                        <SharedAvatar
                            objectFit={'cover'}
                            showLabel={!!props.showAlias}
                            alias={m.alias}
                            size={TypeAvatarSize.s40}
                            image={m.imageUrl || undefined}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default KuboMembersSlide;
