import React from 'react';
import { Kubo } from 'framework/api/models/kubo';
import { useStyles } from '../kubo-invitation-info.style';
import { Box, Divider, Link, Typography } from '@mui/material';
import SharedAvatar, { TypeAvatarSize } from 'framework/web/shared/shared-avatar/shared-avatar';
import KuboMembersSlide from 'features/components/kubo-members-slide/kubo-members-slide';
import CostTypesDivisionVariable from 'features/components/cost-types/cost-types-division-variable/cost-types-division-variable';
import { PRODUCT_TYPE } from 'framework/api/models/product';
import CostTypesClosedProduct from 'features/components/cost-types/cost-types-closed-product/cost-types-closed-product';
import { useI18nService } from 'framework/i18n/i18n-service';

type KuboInfoProps = {
    kubo: Kubo;
    onClickShowMore: () => void;
};

const KuboInfo = (props: KuboInfoProps) => {
    const { t } = useI18nService();
    const { classes: styles } = useStyles();

    return (
        <>
            <Box className={styles.containerVendorInfo}>
                <SharedAvatar image={props.kubo.product.vendor.logo} size={TypeAvatarSize.s40} />
                <Typography color={'common.whiteC.c100'} variant={'body14Medium'}>
                    {props.kubo.product.vendor.name}
                </Typography>
            </Box>

            <Box className={styles.containerDescription}>
                <Typography
                    className={styles.title}
                    color={'common.whiteC.c100'}
                    variant={'Heading1'}
                >
                    {props.kubo.title}
                </Typography>

                <Typography
                    dangerouslySetInnerHTML={{ __html: props.kubo.product.description }}
                    className={styles.description}
                    color={'common.whiteC.c100'}
                    variant={'body14Regular'}
                />

                <Link
                    component={'button'}
                    color={'common.whiteC.c100'}
                    style={{ alignSelf: 'flex-end', marginTop: 10 }}
                    variant={'body14Regular'}
                    onClick={props.onClickShowMore}
                >
                    {t('showMore')}
                </Link>
            </Box>

            <KuboMembersSlide
                maxMembersIntoKubo={props.kubo.maxMembers}
                kuboType={props.kubo.type}
                showAlias={true}
                kuboMembers={props.kubo.membersInfo}
            />

            <Divider className={styles.dividerSlideMembers} />

            {props.kubo.product.productType === PRODUCT_TYPE.VARIABLE ? (
                <CostTypesDivisionVariable
                    minMembers={props.kubo.minMembers}
                    maxMembers={props.kubo.maxMembers}
                    maxMemberCostView={props.kubo.maxMemberCostView}
                    minMemberCostView={props.kubo.minMemberCostView}
                />
            ) : props.kubo.product.productType === PRODUCT_TYPE.CLOSED ? (
                <CostTypesClosedProduct
                    marketingPrice={[
                        {
                            priceView: props.kubo.maxMemberCostView,
                            members: props.kubo.maxMembers
                        }
                    ]}
                    title={t('costTypesClosedProductTitle')}
                />
            ) : null}
        </>
    );
};

export default KuboInfo;
