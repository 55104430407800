import React, { lazy, ReactElement } from 'react';
import { invitesRoutes } from 'routing/framework/routes/invites';
import AppLayout from 'framework/web/layout/app-layout';
import { RouteN } from 'routing/routes-path';
import { Navigate } from 'react-router-dom';

const Error = lazy(() => import('features/404/error-404'));

export type RoutesType = {
    path: string;
    suspense: boolean;
    element: ReactElement;
    id: string;
    children?: RoutesType[];
};

const routes: RoutesType[] = [
    {
        id: 'app-layout',
        path: '/',
        suspense: false,
        element: <AppLayout />,
        children: [
            {
                path: '',
                id: 'invitation-navigate',
                suspense: true,
                element: <Navigate to={RouteN.invites.route} replace={true} />
            },
            ...invitesRoutes,
            {
                id: 'error404',
                path: RouteN.error404.route,
                suspense: true,
                element: <Error />
            }
        ]
    }
];

export default routes;
