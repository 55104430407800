import React from 'react';
import { Box, Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';

const Error404 = () => {
    const { t } = useI18nService();
    return (
        <Box textAlign={'center'} display={'flex'} justifyContent={'center'} mt={40}>
            <Typography color={'common.whiteC.c100'} variant={'Heading1'}>
                {t('invitationExpired')}
            </Typography>
        </Box>
    );
};

export default Error404;
