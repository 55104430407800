import AWS from 'aws-sdk';
import { InvocationRequest } from 'aws-sdk/clients/lambda';
import { API_METHODS } from '../my-api';

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION || ''
});

AWS.config.credentials = new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || ''
});

export type ResponseAWSCustom = {
    body: string;
    statusCode: number;
};

export const myAWSCall = async (
    lambdaFunctionName: string,
    method: API_METHODS,
    headers: Object,
    body?: Object
): Promise<ResponseAWSCustom> => {
    const lambda = new AWS.Lambda();

    const params: InvocationRequest = {
        FunctionName: lambdaFunctionName,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
            httpMethod: method,
            headers: headers,
            body: JSON.stringify(body)
        })
    };

    const res = await lambda.invoke(params).promise();

    const payloadParse = JSON.parse(!!res.Payload ? res.Payload.toString() : '');

    return {
        body: payloadParse.body,
        statusCode: payloadParse.statusCode
    };
};
