import React from 'react';
import { Typography } from '@mui/material';
import { useI18nService } from 'framework/i18n/i18n-service';
import { PRODUCT_TYPE } from 'framework/api/models/product';

type ProductInvitationHeaderProps = {
    productType: PRODUCT_TYPE;
};

const ProductInvitationHeader = (props: ProductInvitationHeaderProps) => {
    const { t } = useI18nService();

    return (
        <Typography flex={1} color={'common.whiteC.c100'} variant={'DisplaySmall'}>
            {t(
                props.productType === PRODUCT_TYPE.VARIABLE
                    ? 'titleInvitationProductVariable'
                    : 'titleInvitationProductClosed'
            )}
        </Typography>
    );
};

export default ProductInvitationHeader;
