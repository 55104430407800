import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { SizeIconType } from 'framework/web/shared/utils/icon-sizes';
import { TypeAvatarSize } from './shared-avatar';

type StylesProps = {
    size: TypeAvatarSize;
};

export const useStyles = makeStyles<StylesProps>()((theme: Theme, { size }) => ({
    containerFirstLetter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.grey.c100,
        width: STYLES[size].containerWidth,
        height: STYLES[size].containerHeight,
        borderRadius: STYLES[size].containerBorderRadius
    },
    firstLetter: {
        color: theme.palette.common.whiteC.c100,
        textTransform: 'uppercase',
        textAlignVertical: 'center',
        textAlign: 'center'
    },
    containerIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: theme.palette.common.whiteC.c100_40,
        width: STYLES[size].containerWidth,
        height: STYLES[size].containerHeight,
        borderRadius: STYLES[size].containerBorderRadius
    },
    text: {
        marginTop: 8,
        color: theme.palette.common.whiteC.c100,
        textAlignVertical: 'center',
        textAlign: 'center'
    },
    textAlias: {
        maxWidth: STYLES[size].containerWidth,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        wordBreak: 'break-word'
    }
}));

export const STYLES = {
    ['s48']: {
        containerBorderRadius: 12,
        containerHeight: 48,
        containerWidth: 48,
        iconSize: SizeIconType.s32
    },
    ['s40']: {
        containerBorderRadius: 12,
        containerHeight: 40,
        containerWidth: 40,
        iconSize: SizeIconType.s24
    },
    ['s24']: {
        containerBorderRadius: 8,
        containerHeight: 24,
        containerWidth: 24,
        iconSize: SizeIconType.s16
    }
};
